// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="hover:text-blue-400 transition duration-300">Home</Link></li>
              <li><Link to="/pricing" className="hover:text-blue-400 transition duration-300">Pricing</Link></li>
              <li><Link to="/contact" className="hover:text-blue-400 transition duration-300">Contact</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/refund-policy" className="hover:text-blue-400 transition duration-300">Refund Policy</Link></li>
              <li><Link to="/terms-and-conditions" className="hover:text-blue-400 transition duration-300">Terms of Service</Link></li>
              <li><Link to="/privacy-policy" className="hover:text-blue-400 transition duration-300">Privacy Policy</Link></li>
            </ul>
          </div>
         
        </div>
        <div className="mt-8 text-center">
          <p>&copy; 2024 LocalSEO Starter pack. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;