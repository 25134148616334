import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { FaCheck, FaBars, FaTimes, FaArrowRight, FaClock, FaMoneyBillWave, FaRocket, FaFire } from 'react-icons/fa';

const HomePage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const pulseAnimation = {
    scale: [1, 1.05, 1],
    transition: {
      duration: 1.5,
      repeat: Infinity,
      repeatType: "reverse",
    },
  };

  const steps = [
    { title: "1. Sign Up", description: "Create your account and tell us about your business.", icon: FaRocket },
    { title: "2. Full Site Audit", description: "We thoroughly analyze your website and online presence.", icon: FaCheck },
    { title: "3. Prepare Strategies", description: "Our experts develop a tailored SEO plan for your business.", icon: FaClock },
    { title: "4. Implement Strategies", description: "We execute the plan to boost your local SEO performance.", icon: FaMoneyBillWave }
  ];

  const services = [
    "Foundational link building", "Local citations", "NAP optimization", 
    "Free consultation", "Google Business Profile optimization", "Silo structure setup",
    "5 keyword research", "5 article structures", "Schema implementation",
    "Backlink outreach", "Full site audit", "Internal link management",
    "10 service page optimizations"
  ];

  const comparisonData = [
    { feature: 'Price', normal: '$999/month', localSEO: '$269/month' },
    { feature: 'Foundational link building', normal: true, localSEO: true },
    { feature: 'Local citations', normal: true, localSEO: true },
    { feature: 'NAP optimization', normal: true, localSEO: true },
    { feature: 'Free consultation', normal: false, localSEO: true },
    { feature: 'Google Business Profile optimization', normal: true, localSEO: true },
    { feature: 'Silo structure setup', normal: false, localSEO: true },
    { feature: 'Keyword research', normal: '2 keywords', localSEO: '5 keywords' },
    { feature: 'Article structures', normal: '2 articles', localSEO: '5 articles' },
    { feature: 'Schema implementation', normal: false, localSEO: true },
    { feature: 'Backlink outreach', normal: '5 links', localSEO: '10 links' },
    { feature: 'Full site audit', normal: false, localSEO: true },
    { feature: 'Internal link management', normal: false, localSEO: true },
    { feature: 'Service page optimizations', normal: '5 pages', localSEO: '10 pages' },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white font-sans">
      <header className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-4 sticky top-0 z-50">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between items-center">
            <motion.h1 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="text-xl md:text-3xl font-extrabold"
            >
              LocalSEO Starter Pack
            </motion.h1>

            <div className="flex items-center md:order-3">
             

              <motion.button 
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="md:hidden text-white focus:outline-none"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
              </motion.button>
            </div>

            <nav className={`w-full md:w-auto ${isMenuOpen ? 'block' : 'hidden'} md:block mt-4 md:mt-0 md:order-2`}>
              <ul className="flex flex-col md:flex-row md:space-x-6 space-y-2 md:space-y-0 text-lg">
                {['Home', 'Pricing', 'How it Works', 'Samples'].map((item, index) => (
                  <motion.li key={index} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <a href={`#${item.toLowerCase().replace(' ', '-')}`} className="hover:text-blue-200 transition duration-300">
                      {item}
                    </a>
                  </motion.li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <motion.section 
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        className="flex-grow flex items-center justify-center bg-white text-blue-600 px-4 py-16 md:py-24 relative overflow-hidden"
      >
        <div className="max-w-4xl mx-auto text-center z-10">
          <motion.h1 
            className="text-3xl md:text-5xl lg:text-6xl font-extrabold mb-6 leading-tight tracking-tight"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Get The Full Benefits Of A Local SEO Agency{' '}
            <motion.span 
              className="bg-gradient-to-r from-blue-600 to-blue-800 text-white px-4 py-2 rounded-lg inline-block mt-2"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              For Only $269
            </motion.span>
          </motion.h1>
          <motion.p 
            className="text-lg md:text-xl lg:text-2xl mb-8 text-blue-800"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            Agency-Level Services At A Price Of Just $8.97 Per Day!
          </motion.p>
          <motion.a 
            href="https://nichesite.lemonsqueezy.com/buy/87547453-dd5e-4e0a-868d-18a0dca3caa0"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(0,0,0,0.3)" }}
            whileTap={{ scale: 0.95 }}
            className="relative inline-block bg-gradient-to-r from-blue-600 to-blue-800 text-white font-bold py-4 px-10 rounded-lg shadow-lg text-xl overflow-hidden"
          >
            <motion.div 
              className="absolute inset-0 bg-white opacity-20"
              animate={pulseAnimation}
            />
            <div className="relative z-10 flex items-center justify-center">
              <span>Only 2 Spots Left</span>
              <FaFire className="ml-2 text-yellow-400" />
            </div>
          </motion.a>
        </div>
      </motion.section>

      <section className="bg-white py-16 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <motion.h2 
            className="text-3xl md:text-4xl font-bold mb-4 text-blue-600"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Welcome to the future of local SEO
          </motion.h2>
          <motion.p 
            className="text-xl mb-8 text-gray-700"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            We've revolutionized the game, bringing you agency-level services at an unbeatable price.
          </motion.p>
          <motion.div 
            className="bg-white rounded-xl shadow-lg p-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <h3 className="text-2xl font-semibold mb-6 text-blue-600">For only $269 per month, your local business gets:</h3>
            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {services.map((service, index) => (
                <motion.li 
                  key={index} 
                  className="flex items-center bg-blue-50 p-4 rounded-lg text-lg"
                  whileHover={{ scale: 1.03, backgroundColor: "#E3F2FD" }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <FaCheck className="w-6 h-6 mr-3 text-green-500 flex-shrink-0" />
                  <span>{service}</span>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </div>
      </section>

      <section id="how-it-works" className="bg-gray-100 py-16 px-4">
        <div className="max-w-4xl mx-auto">
          <motion.h2 
            className="text-3xl md:text-4xl font-bold mb-10 text-center text-blue-800"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            How it Works
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {steps.map((step, index) => (
              <motion.div 
                key={index}
                className="bg-white p-6 rounded-xl shadow-lg cursor-pointer"
                whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(0,0,0,0.1)" }}
                onClick={() => setActiveStep(index)}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <step.icon className="text-4xl text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-3">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <motion.section 
        id="samples"
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={fadeIn}
        className="bg-white py-16 px-4"
      >
        <div className="max-w-6xl mx-auto">  
          <h2 className="text-3xl md:text-4xl font-bold mb-10 text-center text-blue-800">This is what you'll get for $269</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { img: "https://i.ibb.co/H7YdrmW/1500x500.webp", title: "Scaled 23 Leads Per Month To 340 Leads" },
              { img: "https://i.ibb.co/BG0S0m8/GKs9-Mrd-WUAAl4qd.webpp", title: "0 to 200+ Calls in 3 Month" },
              { img: "https://i.ibb.co/KFRgKw1/do-best-on-page-seo-optimization-for-your-wordpress-website-2.webp", title: "#1 pool maintenance near me" }
            ].map((item, index) => (
              <motion.div 
                key={index}
                className="bg-white rounded-lg overflow-hidden shadow-lg"
                whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(0,0,0,0.2)" }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <img 
                  src={item.img}
                  alt={item.title}
                  className="w-full h-64 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      <motion.section 
        id="pricing"
        className="bg-gray-100 py-16 px-4"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold mb-10 text-center text-blue-800">Pricing</h2>
          <motion.div 
            className="bg-white rounded-xl shadow-2xl p-8"
            whileHover={{ boxShadow: "0 20px 30px rgba(0,0,0,0.2)" }}
          >
            <h3 className="text-3xl font-semibold mb-6 text-center text-blue-600">Limited Time Offer</h3>
            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
              {services.map((service, index) => (
                <motion.li 
                  key={index} 
                  className="flex items-center text-lg"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <FaCheck className="w-5 h-5 mr-3 text-green-500 flex-shrink-0" />
                  <span>{service}</span>
                </motion.li>
              ))}
            </ul>
            <div className="flex justify-between items-center mb-4">
              <span className="text-2xl font-semibold">Spots Available</span>
              <span className="text-2xl font-semibold"><CountUp end={2} duration={2} /> out of 5</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-3 mb-6">
              <motion.div 
                className="bg-gradient-to-r from-blue-600 to-blue-800 h-3 rounded-full"
                initial={{ width: 0 }}
                animate={{ width: '60%' }}
                transition={{ duration: 1, delay: 0.5 }}
              ></motion.div>
            </div>
            <div className="text-center">
              <motion.a 
                href="https://nichesite.lemonsqueezy.com/buy/87547453-dd5e-4e0a-868d-18a0dca3caa0"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="inline-block bg-gradient-to-r from-blue-600 to-blue-800 text-white font-bold py-3 px-8 rounded-full text-xl shadow-lg hover:shadow-xl transition duration-300"
              >
                <span className="mr-2">$269</span>
                <span className="ml-2">[Limited time offer]</span>
              </motion.a>
              <p className="mt-4 text-lg text-gray-600">We only accept a limited number of orders.</p>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section 
        className="bg-white py-16 px-4"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold mb-10 text-center text-blue-800">LocalSEO Suite vs. Normal SEO Agency</h2>
          <div className="overflow-x-auto">
            <table className="w-full text-left border-collapse shadow-lg">
              <thead>
                <tr className="bg-gradient-to-r from-blue-600 to-blue-800 text-white">
                  <th className="py-4 px-6 font-bold uppercase text-lg">Feature</th>
                  <th className="py-4 px-6 font-bold uppercase text-lg">Normal SEO Agency</th>
                  <th className="py-4 px-6 font-bold uppercase text-lg">LocalSEO Suite</th>
                </tr>
              </thead>
              <tbody>
                {comparisonData.map((item, index) => (
                  <motion.tr 
                    key={index}
                    className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}
                    whileHover={{ backgroundColor: "#E3F2FD" }}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.05 }}
                  >
                    <td className="py-4 px-6 font-semibold text-lg">{item.feature}</td>
                    <td className="py-4 px-6 text-lg">
                      {typeof item.normal === 'boolean' ? 
                        (item.normal ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />) :
                        item.normal
                      }
                    </td>
                    <td className="py-4 px-6 font-bold text-lg text-green-600">
                      {typeof item.localSEO === 'boolean' ? 
                        (item.localSEO ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />) :
                        item.localSEO
                      }
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </motion.section>

      <motion.section 
        className="bg-gray-100 text-center py-16 px-4"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-blue-800">Get Started with LocalSEO Suite Today!</h2>
          <p className="text-xl mb-10 text-gray-700">Don't miss out on this limited-time offer. Boost your local SEO performance now!</p>
          <motion.a 
            href="https://nichesite.lemonsqueezy.com/buy/87547453-dd5e-4e0a-868d-18a0dca3caa0"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.05, backgroundColor: "#2563EB" }}
            whileTap={{ scale: 0.95 }}
            className="inline-block bg-gradient-to-r from-blue-600 to-blue-800 text-white font-bold py-4 px-10 rounded-full text-xl shadow-lg hover:shadow-xl transition duration-300"
          >
            Get Started Now
            <FaArrowRight className="inline-block ml-2" />
          </motion.a>
        </div>
      </motion.section>

      <footer className="bg-gray-800 text-white py-8 md:py-12">
        <div className="container mx-auto px-4 text-center">
          <p className="mb-4 text-lg">&copy; 2024 LocalSEO Starter Pack. All rights reserved.</p>
          <div className="flex flex-wrap justify-center space-x-4 md:space-x-6">
            <Link to="/refund-policy" className="hover:text-blue-400 transition duration-300 text-base md:text-lg mb-2">Refund Policy</Link>
            <Link to="/terms-and-conditions" className="hover:text-blue-400 transition duration-300 text-base md:text-lg mb-2">Terms of Service</Link>
            <Link to="/contact" className="hover:text-blue-400 transition duration-300 text-base md:text-lg mb-2">Contact Us</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;