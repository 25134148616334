import React, { useState } from 'react';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-blue-600 text-white py-6 sticky top-0 z-50">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center">
          <h1 className="text-2xl md:text-4xl font-extrabold">LocalSEO Starter Pack</h1>
          
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="text-sm md:text-xl font-semibold hidden md:block"
          >
            <CountUp end={13} duration={2} />+ local service owners using this...
          </motion.div>

          <button 
            className="md:hidden text-white focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>

          <nav className={`w-full md:w-auto ${isMenuOpen ? 'block' : 'hidden'} md:block mt-4 md:mt-0`}>
            <ul className="flex flex-col md:flex-row md:space-x-6 space-y-2 md:space-y-0 text-lg">
              <li><a href="#" className="hover:text-blue-200 transition duration-300">Home</a></li>
              <li><a href="#pricing" className="hover:text-blue-200 transition duration-300">Pricing</a></li>
              <li><a href="#how-it-works" className="hover:text-blue-200 transition duration-300">How it Works</a></li>
              <li><a href="#samples" className="hover:text-blue-200 transition duration-300">Samples</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;