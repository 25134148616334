import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const RefundPolicy = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Refund Policy</h1>
        <div className="prose max-w-none">
          <p>At LocalSEO Suite, we strive to provide the best service possible. However, we understand that there may be instances where a refund is necessary. Please read our refund policy carefully:</p>
          
          <h2 className="text-2xl font-semibold mt-6 mb-4">30-Day Money-Back Guarantee</h2>
          <p>We offer a 30-day money-back guarantee on our services. If you are not satisfied with our service within the first 30 days of your subscription, you can request a full refund.</p>
          
          <h2 className="text-2xl font-semibold mt-6 mb-4">Refund Process</h2>
          <p>To request a refund, please contact our support team at support@localseosuite.com within the 30-day period. Please provide your account details and the reason for your refund request.</p>
          
          <h2 className="text-2xl font-semibold mt-6 mb-4">Exceptions</h2>
          <p>Please note that refunds will not be provided for:</p>
          <ul className="list-disc pl-6">
            <li>Services already rendered</li>
            <li>Requests made after the 30-day period</li>
            <li>Misuse of our services</li>
          </ul>
          
          <p className="mt-6">We reserve the right to make exceptions to this policy at our discretion. Each refund request will be reviewed on a case-by-case basis.</p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default RefundPolicy;